ï»¿const $ = require("jquery");
const dataUtils = require("../Utilities/plex-utils-data");
const pubsub = require("../Core/plex-pubsub");
const Action = require("./plex-actions");
const http = require("../Core/plex-navigate");
const DataResult = require("../Data/plex-data-result");
const plexExport = require("../../global-export");
const notify = require("../Core/plex-notify");
const jsUtils = require("../Utilities/plex-utils-js");

function defined(value, defaultValue) {
  return value === undefined ? defaultValue : value;
}

const SearchAction = Action.extend({
  onInit: function () {
    this.publishSearch = defined(this.publishSearch, true);
    this.config.showOverlay = defined(this.config.showOverlay, true);

    // todo: make this cleaner - this was intended to allow exports to disable validation
    this.validatable = defined(this.validate, this.validatable);
  },

  shouldExecute: function (request) {
    if (this.publishSearch) {
      // fire pre-search, giving elements a chance to unsubscribe and cancel the search (ie mastergrid)
      pubsub.publish(`searching.${this.id}`, request);

      // ignore if there are no listeners
      if (!pubsub.hasSubscriptions(`searched.${this.id}`) && !this.postAction) {
        return false;
      }
    }

    return true;
  },

  onExecute: function (data) {
    const self = this;

    if (!this.parent) {
      notify.error("The data source has not been set on the Execute Data Source Action.");
      return new $.Deferred().reject();
    }

    if (!this.address) {
      // TODO: for now just ignore until we can figure out why VP is keeping around empty actions
      notify.error({
        text: "Address is not set for action: {1}.",
        tokens: [this.handle || this.id]
      });
      return new $.Deferred().reject();
    }

    const request = this.createRequest(data);
    if (!this.shouldExecute(request)) {
      return new $.Deferred().resolve();
    }

    const url = http.buildUrl(this.address, { limit: this.applyRecordLimits });
    return http.post(url, request, { standardArrays: true, showOverlay: this.config.showOverlay }).then((results) => {
      if (!self.validateResponse(results)) {
        return new $.Deferred().reject();
      }

      const dataResult = new DataResult(results, request);
      const successfulExecuteResult = self.onSuccessfulExecute(data, dataResult);

      if (jsUtils.isPromise(successfulExecuteResult)) {
        return successfulExecuteResult.then(() => {
          return dataResult;
        });
      }

      return dataResult;
    });
  },

  createRequest: function (data) {
    return dataUtils.cleanse(data);
  },

  onSuccessfulExecute: function (data, results) {
    if (this.publishSearch) {
      // notify listeners (ie grids) that data has been retrieved
      pubsub.publish(`searched.${this.id}`, results);
    }
  }
});

module.exports = SearchAction;
plexExport("actions.Search", SearchAction);
