ï»¿const $ = require("jquery");
const ko = require("knockout");
const stringUtils = require("../Utilities/plex-utils-strings");
const gridSelectionMode = require("../Grid/plex-grid-selectionmode");
const nav = require("../Core/plex-navigate");
const plexImport = require("../../global-import");
const plexExports = require("../../global-export");

// Revision tracking allows changes to input elements to be tracked.
// A revision tracking database record will identify the user, date/time of the change,
// and the before/after values of each element that was modified.
// Revision tracking can be toggled for an individual element using the TrackRevisions
// property.
//
// Key Terms:
// - Application key: the cloud application key that the revision tracking data is
//     associated with. This could potentially be different than the current application,
//     but usually isn't.
// - Identity key: the database primary key value that uniquely identifies the record
//     that is being modified. e.g.: the account key associated with an account record.
// - Identity name: a user-friendly name to associate with a revision tracking record.
//     This is useful when viewing child revision record links.
// - Context: An object representing the total context of the revision, which is just
//     an aggregate of the application key, identity key, and identity name.
// - Revision tracking entry: an object representing a change to a single input element,
//     consisting of the name of the element, the original value, and the revised value.
// - Revision tracking record: a collection of revision tracking entries which are all
//     associated with a single entity, e.g. a single part.
// - Child revision tracking record: a revision tracking record that is linked to a parent.
//     These are useful for grid records within a form, so that they may be linked from the
//     form's revision tracking grid.
// - Element config: an object defining the UI element, identity/application/name, and
//     a grid element indicator.

function getPageElementConfigs(page) {
  /// <summary>
  /// Gets the elementConfigs of elements within the current page
  /// </summary>
  /// <param name="page">The page for which to track revisions.</param>

  let elementConfigs = [];
  let controller;

  // todo: moved here because of circular reference - need cleaner way than instance check to do this
  // maybe add a "getElements" method to each controller?
  /* eslint global-require: "off" */
  const FormController = require("../Controls/plex-controller-form");
  const GridController = require("../Controls/plex-controller-grid");

  page = page || plexImport("currentPage");
  for (controller in page) {
    if (Object.prototype.hasOwnProperty.call(page, controller)) {
      const viewName = page[controller].config?.viewName?.toLowerCase();
      switch (viewName) {
        case "_form":
          elementConfigs = elementConfigs.concat(getControllerElementConfigs(page[controller], {}));
          break;

        case "_grid":
          elementConfigs = elementConfigs.concat(getGridElementConfigs(page[controller]));
          break;

        default:
          if (page[controller] instanceof FormController) {
            elementConfigs = elementConfigs.concat(getControllerElementConfigs(page[controller], {}));

            // eslint-disable-next-line no-console
            console.log(`Performing legacy element collection for: ${viewName}`);
          } else if (page[controller] instanceof GridController) {
            elementConfigs = elementConfigs.concat(getGridElementConfigs(page[controller]));

            // eslint-disable-next-line no-console
            console.log(`Performing legacy element collection for: ${viewName}`);
          }

          break;
      }
    }
  }

  return elementConfigs;
}

function getGridElementConfigs(gridController) {
  /// <summary>
  /// Gets the elementConfigs of elements within the given gridController
  /// </summary>
  /// <param name="gridController">The gridController for which to get the elementConfigs.</param>

  let elementConfigs = [];
  let records, i;

  // if the selection mode is "dirty", track revisions only on the dirty rows
  // todo: may want to revisit this; perhaps a property on the grid specifically
  // for configuring which rows are revision tracked
  if (
    gridController.dirtyRecords &&
    gridController.config.selectionMode &&
    gridSelectionMode.isDirty(gridController.config.selectionMode)
  ) {
    records = gridController.dirtyRecords.peek();
  } else {
    records = gridController.results.peek();
  }

  records = records || [];
  for (i = 0; i < records.length; i++) {
    const record = records[i];
    if (Object.prototype.hasOwnProperty.call(record, "$$controller")) {
      elementConfigs = elementConfigs.concat(getRecordElementConfigs(record.$$controller, true));
    }
  }

  return elementConfigs;
}

function getRecordElementConfigs(recordElementController, grid) {
  /// <summary>
  /// Gets the elementConfigs of elements within the given recordElementController
  /// </summary>
  /// <param name="recordElementController">The RecordElementController for which to track revisions.</param>
  /// <param name="grid">Value indicating whether the controller is a grid controller.</param>

  if (!recordElementController) {
    return [];
  }

  const parent = recordElementController.parent;
  const record = recordElementController.record;
  const context = {};

  // Standard grids need to have the KeyPropertyName defined in order to
  // identify the identity key associated with each row. For grids within forms,
  // they can be child'ed to the form's revision tracking record.
  if (Object.prototype.hasOwnProperty.call(parent, "keyPropertyName")) {
    const keyPropertyName = parent.keyPropertyName;
    if (
      keyPropertyName &&
      Object.prototype.hasOwnProperty.call(record, keyPropertyName) &&
      typeof record[keyPropertyName] === "number"
    ) {
      context.identityKey = record[keyPropertyName];
    }
  }

  if (Object.prototype.hasOwnProperty.call(parent, "compatibilityKeyPropertyName")) {
    const compatibilityKeyPropertyName = parent.compatibilityKeyPropertyName;
    if (
      compatibilityKeyPropertyName &&
      Object.prototype.hasOwnProperty.call(record, compatibilityKeyPropertyName) &&
      typeof record[compatibilityKeyPropertyName] === "number"
    ) {
      context.compatibilityIdentityKey = record[compatibilityKeyPropertyName];
    }
  }

  if (Object.prototype.hasOwnProperty.call(parent, "valuePropertyName")) {
    const valuePropertyName = parent.valuePropertyName;
    if (valuePropertyName && Object.prototype.hasOwnProperty.call(record, valuePropertyName)) {
      context.identityName = ko.unwrap(record[valuePropertyName]);
    }
  }

  // An application key can optionally be specified in order to associate
  // revision tracking entries to an application other than the current one.
  if (
    parent &&
    Object.prototype.hasOwnProperty.call(parent, "revisionApplicationKey") &&
    parent.revisionApplicationKey > 0
  ) {
    context.applicationKey = parent.revisionApplicationKey;
  }

  context.gridElement = grid || false;

  return getControllerElementConfigs(recordElementController, context);
}

function getControllerElementConfigs(controller, context) {
  /// <summary>
  /// Gets the elementConfigs of elements within the given controller
  /// </summary>
  /// <param name="controller">The controller for which to track revisions.</param>
  /// <param name="context">The revision context (optional).</param>

  const elementConfigs = [];

  if (Object.prototype.hasOwnProperty.call(controller, "elements")) {
    Object.keys(controller.elements).forEach((element) => {
      addElementConfig(elementConfigs, controller.elements[element], context);
    });
  }

  return elementConfigs;
}

function addElementConfig(elementConfigs, element, context) {
  /// <summary>
  /// Adds a new elementConfig to the elementConfigs collection,
  /// as long as it does not already exist in the collection.
  /// </summary>
  /// <param name="elements">The element collection.</param>
  /// <param name="element">The element for which to track revisions.</param>
  /// <param name="context">The revision context (optional).</param>

  let i;
  let tracked = false;

  if (element.propertyName && "displayValue" in element && element.trackRevisions === true) {
    for (i = 0; i < elementConfigs.length; i++) {
      if (elementConfigs[i].element.id === element.id) {
        tracked = true;
        break;
      }
    }

    if (tracked === false) {
      elementConfigs.push(getElementConfig(element, context));
    }
  }
}

function getElementConfig(element, context) {
  /// <summary>
  /// Returns a new elementConfig with the provided options.
  /// </summary>
  /// <param name="elements">The element collection.</param>
  /// <param name="element">The element for which to track revisions.</param>
  /// <param name="context">The revision context (optional).</param>

  return {
    element,
    identityKey: context.identityKey || null,
    compatibilityIdentityKey: context.compatibilityIdentityKey || null,
    applicationKey: context.applicationKey || null,
    identityName: context.identityName || null,
    gridElement: context.gridElement || false
  };
}

function getRevisionTrackingRecords(data, gridRecord, controller) {
  /// <summary>
  /// Returns the collection of revision tracking records. Normally, this will consist of
  /// a single record associated with either a complete form or a single updateable grid row.
  /// In the case of an updateable grid within a form, multiple revision tracking records will
  /// be created; one for the form and one for each row in the grid.
  /// If the optional controller parameter is supplied, the revision records for that controller
  /// will be returned.
  /// </summary>
  /// <param name="data">The data for which to get the revision tracking records.</param>
  /// <param name="gridRecord">Value indicating whether the data belongs to a grid record.</param>
  /// <param name="controller">Optional parameter for specifying the controller for which to get revision data.</param>

  let elementConfigs;

  if (controller) {
    elementConfigs = getRecordElementConfigs(controller, false);
  } else if (gridRecord) {
    elementConfigs = getRecordElementConfigs(data.$$controller, true);
  } else {
    elementConfigs = getPageElementConfigs(plexImport("currentPage"));
  }

  return getRevisionTrackingRecordsForElementConfigs(elementConfigs);
}

function getRevisionTrackingEntry(label, fieldLabelGlossaryTokens, originalValue, revisionValue) {
  /// <summary>
  /// Gets a revision object with the specified properties
  /// </summary>
  /// <param name="label">The label of the element that is being revised.</param>
  /// <param name="fieldLabelGlossaryTokens">The glossary tokens for the label.</param>
  /// <param name="originalValue">The original value of the element.</param>
  /// <param name="revisionValue">The revision value of the element.</param>

  return {
    OriginalValue: Array.isArray(originalValue) ? originalValue.join(", ") : originalValue,
    RevisionValue: Array.isArray(revisionValue) ? revisionValue.join(", ") : revisionValue,
    Field: label > "" ? label : "[No label]",
    FieldGlossaryTokens: Array.isArray(fieldLabelGlossaryTokens)
      ? fieldLabelGlossaryTokens.join(",")
      : fieldLabelGlossaryTokens
  };
}

function getRevisionTrackingRecordsForElementConfigs(elementConfigs) {
  /// <summary>
  /// Returns a collection of revision tracking records for the given elementConfigs
  /// </summary>
  /// <param name="elementConfigs">The elementConfigs from which to create the revision tracking records.</param>

  let identityKey,
    applicationKey,
    identityName,
    gridElement,
    el,
    compatibilityIdentityKey,
    i,
    j,
    label,
    glossaryTokens,
    originalValue,
    revisionValue,
    revisionTrackingRecord,
    currentRecord,
    revisionTrackingEntry;
  const revisionTrackingRecords = [];

  for (i = 0; i < elementConfigs.length; i++) {
    el = elementConfigs[i].element;

    // get the original and new values
    originalValue = el.initialDisplayValue;
    revisionValue = el.displayValue();

    // get the identifiers for the record
    identityKey = elementConfigs[i].identityKey;
    compatibilityIdentityKey = elementConfigs[i].compatibilityIdentityKey;
    applicationKey = elementConfigs[i].applicationKey;
    identityName = elementConfigs[i].identityName;

    // values will be processed differently for grid elements
    gridElement = elementConfigs[i].gridElement;

    // track revisions only for modified values, of course
    if (originalValue !== revisionValue) {
      label = getLabelText(el, gridElement);
      glossaryTokens = getLabelGlossaryTokens(el, gridElement);
      revisionTrackingRecord = null;
      for (j = 0; j < revisionTrackingRecords.length; j++) {
        // check if there is an existing record with the same properties
        currentRecord = revisionTrackingRecords[j];
        if (
          ((!identityKey && !currentRecord.identityKey) ||
            (identityKey && currentRecord.identityKey === identityKey)) &&
          ((!compatibilityIdentityKey && !currentRecord.compatibilityIdentityKey) ||
            (compatibilityIdentityKey && currentRecord.compatibilityIdentityKey === compatibilityIdentityKey)) &&
          ((!applicationKey && !currentRecord.applicationKey) ||
            (applicationKey && currentRecord.applicationKey === applicationKey)) &&
          ((!identityName && !currentRecord.identityName) ||
            (identityName && currentRecord.identityName === identityName))
        ) {
          revisionTrackingRecord = currentRecord;
          break;
        }
      }

      revisionTrackingEntry = getRevisionTrackingEntry(label, glossaryTokens, originalValue, revisionValue);
      if (!revisionTrackingRecord) {
        // didn't find a matching record, so create a new base record
        revisionTrackingRecord = {
          identityKey,
          compatibilityIdentityKey,
          applicationKey,
          identityName,
          gridRecord: gridElement || false,
          revisionTrackingEntries: []
        };
        revisionTrackingRecords.push(revisionTrackingRecord);
      }

      revisionTrackingRecord.revisionTrackingEntries.push(revisionTrackingEntry);
    }
  }

  return revisionTrackingRecords;
}

function getLabelText(el, gridElement) {
  /// <summary>
  /// Get the label text for the element
  /// </summary>
  /// <param name="el">The element for which to get the label text.</param>
  /// <param name="gridElement">Value indicating whether the element is a grid element.</param>

  if (el.revisionTrackingLabelPropertyName) {
    // support lambda expressions in the Revision Tracking Label
    let label;
    if (gridElement) {
      label = stringUtils.format(el.revisionTrackingLabel, el.parent.record[el.revisionTrackingLabelPropertyName]);
    } else if (el.controller) {
      // WithInputFor does not have a controller in a form so check the parent's data instead
      label = stringUtils.format(el.revisionTrackingLabel, el.controller.model[el.revisionTrackingLabelPropertyName]);
    } else {
      label = stringUtils.format(el.revisionTrackingLabel, el.parent.data[el.revisionTrackingLabelPropertyName]);
    }

    return label;
  } else if (el.revisionTrackingLabel) {
    return el.revisionTrackingLabel;
  }

  if (gridElement) {
    // for a grid element, use the grid's header
    return getCellHeaderText(el);
  } else {
    // otherwise use the associated label
    return getFieldLabelText(el);
  }
}

function getLabelGlossaryTokens(el, gridElement) {
  /// <summary>
  /// Get the label glossary tokens for the element
  /// </summary>
  /// <param name="el">The element for which to get the label glossary tokens.</param>
  /// <param name="gridElement">Value indicating whether the element is a grid element.</param>

  if (gridElement) {
    return getCellHeaderGlossaryTokens(el);
  } else {
    return getFieldLabelGlossaryTokens(el);
  }
}

function getCellHeaderText(element) {
  /// <summary>
  /// Get the text of the header cell of the element's column
  /// </summary>
  /// <param name="element">The element for which to get the header text.</param>

  let headerText = "";

  // perhaps there's a better way to do this...
  if (element.parent && element.parent.parent && element.parent.parent.viewName === "_Grid" && element.colIndex) {
    const column = element.parent.parent.columns[element.colIndex];

    // the "original" header name is the unglossarized value
    if (column.originalHeaderName) {
      headerText = column.originalHeaderName;
    }
  }

  // default to the property name, formatted
  if (headerText === "" && Object.prototype.hasOwnProperty.call(element, "propertyName")) {
    // this will also add spaces
    headerText = stringUtils.toTitleCase(element.propertyName);
  }

  return headerText;
}

function getCellHeaderGlossaryTokens(element) {
  /// <summary>
  /// Get the glossary tokens of the header cell of the element's column
  /// </summary>
  /// <param name="element">The element for which to get the header text.</param>

  let glossaryTokens = "";

  // perhaps there's a better way to do this...
  if (element.parent && element.parent.parent && element.parent.parent.viewName === "_Grid" && element.colIndex) {
    const column = element.parent.parent.columns[element.colIndex];

    // Obtain the glossary tokens, if available
    if (column.glossaryTokens) {
      glossaryTokens = column.glossaryTokens;
    }
  }

  return glossaryTokens;
}

function getFieldLabelText(element) {
  /// <summary>
  /// Get the label text associated with the element
  /// </summary>
  /// <param name="element">The element for which to get the label text.</param>

  let labelText = "";

  // Find the associated label element
  const $label = $("label[for='" + element.id + "']");

  if ($label.length) {
    // if we find one of the plex error labels, ignore it.
    // we will try using the element's property name instead.
    if ($label.attr("class") !== "plex-error") {
      // Use the unglossarized text, if available
      labelText = $label.attr("data-original-text");

      // Otherwise use the text
      if (typeof labelText === "undefined" || labelText === "") {
        labelText = $label.text().trim();
      }
    }
  }

  // Default to the property name, formatted
  if (labelText === "" && Object.prototype.hasOwnProperty.call(element, "propertyName")) {
    labelText = stringUtils.toTitleCase(element.propertyName);
  }

  return labelText;
}

function getFieldLabelGlossaryTokens(element) {
  /// <summary>
  /// Get the label glossary tokens associated with the element
  /// </summary>
  /// <param name="element">The element for which to get the label glossary tokens.</param>

  let labelGlossaryTokens = "";
  let labelElement = "";

  // Find the associated label element
  const $label = $("label[for='" + element.id + "']");

  // Find the actual label element
  // only if a label has been assigned
  if ($label.length > 0) {
    // if we find one of the plex error labels, ignore it.
    if ($label.attr("class") !== "plex-error") {
      if (element.parent) {
        labelElement = element.parent.elements[$label.attr("id")];
      }

      // check that the label has a glossary token property
      if (labelElement.glossaryTokens) {
        // Obtain the glossary tokens, if available
        labelGlossaryTokens = labelElement.glossaryTokens;
      }
    }
  }

  return labelGlossaryTokens;
}

function addRevisionDataToPostData(postData, controller) {
  /// <summary>
  /// Adds revision tracking data to the post data
  /// </summary>
  /// <param name="postData">The post data.</param>
  /// <param name="controller">Optional parameter for specifying the controller for which to get revision data.</param>

  // custom Revision Tracking
  if (postData.customRevisionTracking) {
    return;
  }

  if (controller) {
    postData.__revisionTrackingData = getRevisionTrackingRecords(postData, false, controller);
  } else if (Array.isArray(postData)) {
    // postData in an array format indicates an updateable grid, with one entry per row
    let i;
    for (i = 0; i < postData.length; i++) {
      postData[i].__revisionTrackingData = getRevisionTrackingRecords(postData[i], true);
    }
  } else {
    postData.__revisionTrackingData = getRevisionTrackingRecords(postData, false);
  }
}

function recordRevisions(revisionTrackingContext) {
  /// <summary>
  /// Record revisions directly
  /// </summary>
  /// <param name="revisionTrackingContext">The revision tracking context.</param>

  revisionTrackingContext = revisionTrackingContext || {};
  const postData = {
    __revisionTrackingData: getRevisionTrackingRecords(null, false, null),
    __revisionTrackingContext: {
      applicationKey: revisionTrackingContext.applicationKey || plexImport("currentApplication").ApplicationKey,
      identityKey: revisionTrackingContext.identityKey || null,
      compatibilityIdentityKey: revisionTrackingContext.compatibilityIdentityKey || null,
      identityName: revisionTrackingContext.identityName || null,
      type: revisionTrackingContext.revisionType || "Update"
    }
  };
  return nav.post(nav.buildUrl("/Platform/RevisionTracking/Record"), postData, { showOverlay: true });
}

const api = {
  getPageElementConfigs,
  getGridElementConfigs,
  getRecordElementConfigs,
  getControllerElementConfigs,
  getElementConfig,
  getRevisionTrackingRecordsForElementConfigs,
  addRevisionDataToPostData,
  recordRevisions
};

module.exports = api;
plexExports("revisionTracking", api);
