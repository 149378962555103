import React from "react";
import plexExport from "../../global-export";

export const useSubscribable = (subscribable) => {
  // Get a stable reference to the subscribe/dispose function
  // to prevent unnecessary subscribe/unsubscribe calls
  const subscribe = React.useCallback(
    (fn) => {
      const sub = subscribable.subscribe(fn);
      return () => sub.dispose();
    },
    [subscribable]
  );

  return React.useSyncExternalStore(subscribe, subscribable);
};

plexExport("Components.useSubscribable", useSubscribable);
